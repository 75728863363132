import { createRouter, createWebHistory } from 'vue-router'
import auth_routhes from './auth_routes'
import dashboard_routes from './dashboard_routes'
import contact_routes from './contact_routes'
import blogs_routes from './blogs_routes'
import admin_routes from './admin_routes'
const router = createRouter({
  history: createWebHistory(),
  routes : [].concat(auth_routhes, dashboard_routes, contact_routes, blogs_routes, admin_routes)
})

export default router
