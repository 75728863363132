// Auth routes
// Views
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/Forget_password.vue'
import ConfirmEmail from '../views/auth/Confirm_email.vue'
import ResetPassword from '../views/auth/Reset_password.vue'
import ConfirmEmailValidate from '../views/auth/Confirm_email_validate.vue'
import axios from '@/axios'

// Routes

export default [
    {
        path: "/auth/login",
        name: "login",
        component : Login
    },
    {
        path: "/auth/register",
        name: "register",
        component : Register
    },
    {
        path: "/auth/forgot-password",
        name: "forgot-password",
        component : ForgotPassword
    },
    {
        path: "/auth/reset-password/:token",
        name: "reset-password",
        component : ResetPassword,
        beforeEnter: (to, from, next) => {
            if (!to.params.token) {
                next({ name: 'login' })
            } 
            axios.get('auth/reset-password/' + to.params.token).then((response) => {
                if (response.data.status == 'failed') {
                    next({ name: 'login' })
                }else{
                    next()
                }
            })
        }
    },
    {
        path: "/auth/confirm-email",
        name: "confirm-email",
        component : ConfirmEmail
    },
    {
        path: "/auth/confirm-email/:token",
        name: "confirm-email-validate",
        component : ConfirmEmailValidate,
        beforeEnter: (to, from, next) => {
            if (!to.params.token) {
                next({ name: 'login' })
            } 
            axios.get('auth/confirm-email/' + to.params.token).then((response) => {
                if (response.data.status == 'failed') {
                    next({ name: 'login' })
                }else{
                    next()
                }
            })
        }

    }
]