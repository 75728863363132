<template>
    <div>
        <h1>Admin Login</h1>
        <input type="text" placeholder="Username" v-model="username"/>
        <input type="password" placeholder="Password" v-model="password"/>
        <button @click="login()">Login</button>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestLogin',

    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        login() {
            store.dispatch('admin/login', {
                username: this.username,
                password: this.password,
            }).then((res) => {
                if(res.status == "success"){
                    this.$router.push({ name: 'admin-dashboard' });
                }else{
                    alert(res.message);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
input , button {
    display: block;
    margin: 5px auto;
}
</style>