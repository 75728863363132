<template>
  <h1>Blog #{{ this.$route.params.slug }}</h1>
  <div v-if="blog">
    <h3>{{ blog.title }}</h3>
    <p>{{ blog.description }}</p>
    <p>{{ blog.views }} views</p>
    <p>{{ blog.created_at }}</p>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "NfcsApiTestBlog",

  data() {
    return {
      blog: null,
    };
  },

  mounted() {
    store.dispatch("blog/getBlog", this.$route.params.slug).then((res) => {
      this.blog = res;
    });
  },
};
</script>

<style lang="scss" scoped></style>
