import axios from '@/axios.js';
export default {
    namespaced: true,
    state: {
        requests : null
    },
    mutations : {},
    actions : {
        createRequest({commit}, payload){
            return axios.post('/requests/create', {
                title: payload.title,
                description: payload.description
            }).then((response) => {
                return response.data.slug;
            })
        },
        loadRequests({commit,state}){
            if(state.requests) return;
            return axios.get('/requests/get').then((response) => {
                if(response.data.status === 'success'){
                    state.requests = response.data.requests
                }
            });
        },
        getRequest({state,commit}, slug){
            if(state.requests){
                return state.requests.find((request) => request.slug === slug)
            }
            return axios.get('/requests/get/' + slug).then((response) => {
                if(response.data.status === 'success'){
                    return response.data;
                }
            });
        },
        updateRequest({commit}, payload){
            return axios.post('/requests/update', {
                slug : payload.slug,
                content: payload.content,
            }).then((response) => {
                return response.data;
            })
        }
    },
}