<template>
    <div>
      <h2>Request #{{ this.$route.params.slug }}</h2>
      <div v-if="request">
        <p>
          Status:
          <span v-if="request.status == 1">Opened</span>
            <span v-if="request.status == 2">Pending</span>
            <span v-if="request.status == 3">Closed</span>
            <span v-if="request.status == 4">Solved</span>
        </p>
        <h4>{{ request.title }}</h4>
        <p>{{ request.description }}</p>
        <p>Created at : {{ request.created_at }}</p>
        <h3>Replies</h3>
        <div style="border: 2px solid black" v-for="(reply,i) in replies">
            <h4>{{reply.admin ? 'Admin' : 'You'}} : </h4>
            <p>{{ reply.content }}</p>
            <p>Created at : {{ reply.created_at }}</p>
        </div>
        <hr>
      </div>
      <textarea  placeholder="Add a Reply" v-model="reply" rows="6"></textarea>
        <button @click="addReply">Add Reply</button>
    </div>
  </template>
  
  <script>
  import store from "@/store";
  export default {
    name: "NfcsApiTestRequest",
    data() {
      return {
        request: null,
        replies : null,
        reply : ''
      };
    },
    mounted() {
      store.dispatch("contact/getRequest",this.$route.params.slug).then((response) => {
        //remove loader
          this.request = response.request;
            this.replies = response.replies;
      });
    },
    methods : {
        addReply(){
            store.dispatch("contact/updateRequest",{
                slug : this.$route.params.slug,
                content : this.reply
            }).then((response) => {
                //remove loader
                // this.replies.push(response);
                // this.reply = '';
                //Add Reply
            });
        }
    }
  };
  </script>
  
  <style lang="scss" scoped>
    textarea{
        width: 100%;
    }
</style>