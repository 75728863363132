<template>
    <div>
        Card #{{ this.$route.params.slug }}
        <div v-if="card">
            <p> Status: 
                <span v-if="card.status == 0">Not Activated</span>
                <span v-if="card.status == 1">Activated</span>
            </p>
            <p>Link: {{ card.link }}</p>
            </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestCard',

    data() {
        return {
            card: null 
        };
    },

    mounted() {
        store.dispatch('cards/getCard',this.$route.params.slug).then((response) => {
            //remove loader
            this.card = response;
        });
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>