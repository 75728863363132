import Index from "../views/admin/Index.vue";
import Login from "../views/admin/Login.vue";
// Admins
import Admins from "../views/admin/admins/Admins.vue";
import CreateAdmin from "../views/admin/admins/AddAdmin.vue";
// Requests
import Requests from "../views/admin/requests/Requests.vue";
import Request from "../views/admin/requests/Request.vue";
// Users
import Users from "../views/admin/users/Users.vue";
// Orders
import Orders from "../views/admin/orders/Orders.vue";
import Order from "../views/admin/orders/Order.vue";

export default [
    {
        path: "/admin/login",
        name: "admin-login",
        component : Login
    },
    {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        component : Index
    },
    // Admins
    {
        path: "/admin/admins",
        name: "admin-admins",
        component : Admins
    },
    {
        path: "/admin/add-admin",
        name: "admin-create-admin",
        component : CreateAdmin
    },
    // Requests
    {
        path: "/admin/requests",
        name: "admin-requests",
        component : Requests
    },
    {
        path: "/admin/requests/:id",
        name: "admin-request",
        component : Request
    },
    // Users
    {
        path: "/admin/users",
        name: "admin-users",
        component : Users
    },
    // Orders
    {
        path: "/admin/orders",
        name: "admin-orders",
        component : Orders
    },
    {
        path: "/admin/orders/:id",
        name: "admin-order",
        component : Order
    }

]