<template>
    <div>
        <h1>Add Admin</h1>
        <input type="text" placeholder="Username" v-model="username"/>
        <input type="password" placeholder="Password" v-model="password"/>
        <input type="password" placeholder="Confirm Password" v-model="confirmPassword"/>
        <!--Access Level-->
        <select v-model="access">
            <option value="1">Full Control</option>
            <option value="2">Moderator</option>
            <option value="3">Support</option>
            <option value="4">Delivery</option>
        </select>
        <button @click="addAdmin()">Add Admin</button>
        <h3 v-if="response">{{ response }}</h3>
    </div>
    <hr>
    <router-link to="/admin/admins">Admins</router-link><br />
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestAddAdmin',

    data() {
        return {
            access: 1,
            username: '',
            password: '',
            confirmPassword: '',
            response: '',
        };
    },
    methods: {
        addAdmin() {
            if(this.password != this.confirmPassword){
                this.response = "Passwords do not match";
                return;
            }
            store.dispatch('admin/addAdmin', {
                username: this.username,
                password: this.password,
                access: this.access,
            }).then((res) => {
                if(res.status == "success"){
                    this.response = "Admin Added";
                }else{
                    this.response = res.message;
                }
            });
        },  
    },
};
</script>

<style lang="scss" scoped>
input , button {
    display: block;
    margin: 5px auto;
}
</style>