<template>
  <div>Make Order</div>
  <div v-if="!category">
    <h3>Choose category</h3>
    <a href="/dashboard/make-order?category=card">Card</a><br />
  </div>
  <div v-else>
    <div v-if="category == 'card'">
      <h2>Card Order</h2>
      <h4 v-if="response">{{ response }}</h4>
      <hr />
      <h4>Card customization</h4>
      <input type="text" placeholder="Link" v-model="link" />
      <canvas
        ref="can"
        width="400"
        height="200"
        style="border: 1px solid #ccc; border-radius: 10px"
      ></canvas>
      <!-- Add the file input element -->
      <h2>Add Image to Card</h2>
      <input type="file" id="imageInput" @change="uploadImage" />
      <h2>Add Text to Card</h2>
      <input type="text" id="text" v-model="card_text" />
      <button @click="addText()">Add</button>
      <div>
        <label>Text Color:</label>
        <input
          type="color"
          v-model="textColor"
          @change="changeTextColor"
        /><br />

        <label>Bold:</label>
        <input type="checkbox" v-model="isBold" @change="changeBold" /><br />

        <label>Italic:</label>
        <input
          type="checkbox"
          v-model="isItalic"
          @change="changeItalic"
        /><br />

        <label>Underline:</label>
        <input
          type="checkbox"
          v-model="isUnderline"
          @change="changeUnderline"
        /><br />

        <label>Font Size:</label>
        <input
          type="number"
          v-model.number="fontSize"
          @change="changeFontSize"
        />
      </div>

      <div id="color-pick">
        <input type="color" id="head" name="head" v-model="card_color" />
        <label for="head">Card Color</label>
      </div>
      <button @click="deleteObject">Remove</button>

      <hr />
      <h4>Order Details</h4>
      <input type="text" v-model="city" placeholder="city" /><br />
      <input type="text" v-model="address" placeholder="address" /><br />
      <input type="text" v-model="phone" placeholder="phone" /><br />
      <h5>Payment Method</h5>
      <input type="radio" v-model="payment_method" value="1" /> Cash<br />
      <input type="radio" v-model="payment_method" value="2" /> Card<br />
      <input type="radio" v-model="payment_method" value="3" />
      Paypal<br />
      <hr />
      <button @click="place_order">Place Order</button><br />
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import html2canvas from "html2canvas";
import store from "@/store";
export default {
  name: "NfcsApiTestMakeOrder",

  data() {
    return {
      link: "",
      city: "",
      address: "",
      phone: "",
      payment_method: "",
      card_color: "#ffffff",
      card_text: "",
      textColor: "#000000",
      isBold: false,
      isItalic: false,
      isUnderline: false,
      fontSize: 20,
      response: "",
    };
  },

  computed: {
    category() {
      return this.$route.query.category;
    },
  },
  watch: {
    card_color: function () {
      this.$refs.can.style.backgroundColor = this.card_color;
    },
  },
  methods: {
    addText() {
      const text = new fabric.Text(this.card_text, {
        left: 100,
        top: 100,
        fontFamily: "helvetica",
        fontSize: this.fontSize,
        fill: this.textColor,
      });

      this.canvas.add(text);
      text.bringToFront();
      this.canvas.renderAll();
    },
    onTextSelected(text) {
      this.textColor = text.fill;
      this.isBold = text.fontWeight === "bold";
      this.isItalic = text.fontStyle === "italic";
      this.isUnderline = text.textDecoration === "underline";
      this.fontSize = text.fontSize;
    },
    changeTextColor() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && activeObject.type === "text") {
        activeObject.set("fill", this.textColor);
        this.canvas.renderAll();
      }
    },
    changeBold() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && activeObject.type === "text") {
        activeObject.set("fontWeight", this.isBold ? "bold" : "normal");
        this.canvas.renderAll();
      }
    },
    changeItalic() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && activeObject.type === "text") {
        activeObject.set("fontStyle", this.isItalic ? "italic" : "normal");
        this.canvas.renderAll();
      }
    },

    changeUnderline() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && activeObject.type === "text") {
        activeObject.set("underline", this.isUnderline ? true : false);
        this.canvas.renderAll();
      }
    },
    changeFontSize() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject && activeObject.type === "text") {
        activeObject.set("fontSize", this.fontSize);
        this.canvas.renderAll();
      }
    },
    uploadImage(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        // Load the image using Fabric.js
        fabric.Image.fromURL(reader.result, (img) => {
          if (
            img.width > this.canvas.width ||
            img.height > this.canvas.height
          ) {
            img.scaleToWidth(this.canvas.width);
            img.scaleToHeight(this.canvas.height);
          }
          // Add the image to the canvas
          this.canvas.add(img);
          img.sendToBack();
        });
      };

      // Read the image file as data URL
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    deleteObject() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        this.canvas.remove(activeObject);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    },
    place_order() {
      //remove any selected object
      this.canvas.discardActiveObject();
      const canvasElement = this.$refs.can;
      const formData = new FormData();
      // Use HTML2Canvas to capture the canvas and its surrounding area
      html2canvas(canvasElement, {
        useCORS: true, // Allow CORS to capture images from external domains
      }).then((canvas) => {
        // Convert the HTML2Canvas output to a data URL
        const dataURL = canvas.toDataURL({
          format: "png",
          multiplier: 4,
        });
        formData.append("image", dataURL);
        formData.append("link", this.link);
        formData.append("city", this.city);
        formData.append("address", this.address);
        formData.append("phone", this.phone);
        formData.append("payment_method", this.payment_method);
        store.dispatch("orders/makeOrder", formData).then((response) => {
          if (response.status == "success") {
            this.response = "Order Placed";
          }
        });
      });
    },
   
  },
  mounted() {
    const ref = this.$refs.can;
    const canvas = new fabric.Canvas(ref);
    this.canvas = canvas;
    this.canvas.selectionColor = "blue";
    this.canvas.selectionBorderColor = "#eee";
    this.canvas.selectionLineWidth = 2;
    this.canvas.selection = true;
    this.canvas.on(
      "selection:created",
      () =>
        //check if the selected object is a text
        this.canvas.getActiveObject().type == "text" ??
        this.onTextSelected(this.canvas.getActiveObject())
    );
    this.canvas.on(
      "selection:updated",
      () =>
        this.canvas.getActiveObject().type == "text" ??
        this.onTextSelected(this.canvas.getActiveObject())
    );

    this.canvas.renderAll();
  },
};
</script>

<style lang="scss" scoped>
input {
  margin: 4px;
}
</style>
