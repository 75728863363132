import axios from "axios";
// check if admin exist in url
if(window.location.href.includes('admin')) {
    var baseURL = "https://nfc-demo-admin.mazenamir.com/api"
    alert(baseURL);

}else{
    var baseURL = "https://nfc-demo.mazenamir.com/api"

}
if (localStorage.getItem('admin-token') || localStorage.getItem('token')) {
    var token = 'Bearer ' + localStorage.getItem('admin-token') || localStorage.getItem('token');
} else {
    var token = '';
}
export default axios.create({
    baseURL: baseURL,

    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Authorization" : token
    }
});