<template>
    <h2>Forgot Password</h2>
    <input type="email" placeholder="email" v-model="email"><br>
    <button @click="forget_password">Forget Password</button><br>
    <div><h3 v-if="response">{{response}}</h3></div>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestRegister',

    data() {
        return {
            email : "",
            response : ""
        };
    },
    methods: {
       forget_password(){
            store.dispatch('auth/forgotPassword', {
                email: this.email
            }).then((response) => {
                if(response.status == "success"){
                    this.response = "An Reset Password Link has been sent to your email address";
                }
            });
        } 
    },
};
</script>

<style lang="scss" scoped>
input , button {
    margin: 4px;
}
</style>