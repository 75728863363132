// Dashboard routes
// Views
import Index from '../views/dashboard/Index.vue'
// Orders
import Orders from '../views/dashboard/orders/Orders.vue'
import Order from '../views/dashboard/orders/Order.vue'
import MakeOrder from '../views/dashboard/orders/MakeOrder.vue'
// Cards
import Cards from '../views/dashboard/cards/Cards.vue'
import Card from '../views/dashboard/cards/Card.vue'
import ActivateCard from '../views/ActivateCard.vue'
export default [
    {
        path: "/dashboard",
        name: "dashboard",
        component : Index
    },
    // Orders
    {
        path: "/dashboard/orders",
        name: "orders",
        component : Orders
    },
    {
        path: "/dashboard/orders/:slug",
        name: "order",
        component : Order
    },
    {
        path: "/dashboard/make-order",
        name: "make-order",
        component : MakeOrder
    },
    // Cards
    {
        path: "/dashboard/cards",
        name: "cards",
        component : Cards
    },
    {
        path: "/dashboard/cards/:slug",
        name: "card",
        component : Card
    },
    {
        path: "/activate/:secret_key",
        name: "activate",
        component : ActivateCard
    }
]