import axios from "@/axios.js"
export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
    },
    mutations : {
        SET_TOKEN(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        }
    },
    actions : {
        login({commit}, user) {
            return axios.post('auth/login', {
                user: user.username,
                password: user.password
            }).then((response) => {
                if(response.data.status == 'failed') return response.data;
                commit('SET_TOKEN', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
                return response.data;
            })
        },
        register({commit}, user) {
            return axios.post('auth/register', {
                name: user.name,
                email: user.email,
                password: user.password,
                username: user.username,
            }).then((response) => {
                return response.data;
            })
        },
        forgotPassword({commit}, user) {
            return axios.post('auth/forgot-password', {
                email: user.email
            }).then((response) => {
                return response.data;
            })
        },
        resetPassword({commit}, data) {
            return axios.post('auth/reset-password', {
                token: data.token,
                password: data.password
            }).then((response) => {
                return response.data;
            })
        },
        sendConfirmEmail({commit}, user) {
            return axios.post('auth/confirm-email', {
                email: user.email
            }).then((response) => {
                return response.data;
            })
        }
    }
}