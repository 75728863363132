<template>
  <div>
    <h1>Admins</h1>
    <h2 v-if="response">{{ response }}</h2>
    <table>
      <tr>
        <td>Id</td>
        <td>Username</td>
        <td>Access</td>
        <td>Control</td>
      </tr>
      <tr v-for="(admin, index) in admins" :key="index">
        <td>{{ admin.id }}</td>
        <td>{{ admin.username }}</td>
        <td>{{ format_access(admin.access_level) }}</td>
        <td>
          <button @click="deleteAdmin(admin.id)">Delete</button>
        </td>
      </tr>
    </table>
  </div>
  <hr>
  <router-link to="/admin/add-admin">Add Admin</router-link>
</template>

<script>
import store from "@/store";

export default {
  name: "NfcsApiTestAdmins",

  data() {
    return {
        response: "",
    };
  },
  methods: {
    format_access(access) {
      if (access == 1) {
        return "Full Control";
      } else if (access == 2) {
        return "Moderator";
      } else if (access == 3) {
        return "Support";
      } else if (access == 4) {
        return "Delivery";
      }
    },
    deleteAdmin(id) {
      store.dispatch("admin/deleteAdmin", id).then((res) => {
        if (res.status == "success") {
          this.response = "Admin Deleted";
        } else {
            this.response = res.message;
        }
      });
    },
  },
  mounted() {
    store.dispatch("admin/getAdmins");
  },
  computed: {
    admins() {
      return store.state.admin.admins;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 50%;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}
</style>
