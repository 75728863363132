<template>
  <h1>Admin Dashboard</h1>
  <h2>Admin Access {{ access }}</h2>
  <div v-if="access == 1">
    <router-link to="/admin/admins">Admins</router-link><br />
    <router-link to="/admin/add-admin">Add Admin</router-link>
  </div>
  <div v-if="access == 1 || access == 2">
    <router-link to="/admin/users">Users</router-link><br />
    <router-link to="/admin/orders">Orders</router-link><br />
    <router-link to="/admin/cards">Cards</router-link><br />
  </div>
  <div v-if="access == 1 || access == 3">
    <router-link to="/admin/requests">Requests</router-link><br />
  </div>
  <div v-if="access == 1 || access == 4">
    <router-link to="/admin/deliveries">Deliveries</router-link><br />
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "NfcsApiTestIndex",

  data() {
    return {};
  },
  computed: {
    access() {
      return store.state.admin.access;
    },
  },
  mounted(){
    if(!this.access){
        this.$router.push({ name: 'admin-login' });
    }
  }
};
</script>

<style lang="scss" scoped>
    div {
        margin: 30px auto;
        width: 50%;
        text-align: center;
    }
</style>
