<template>
    <div v-for="(blog,i) in blogs">
        <h1>Blog #{{ blog.slug }}</h1>
        <h3>{{blog.title}}</h3>
        <p>{{blog.description}}</p>
        <p>{{ blog.views }} views</p>
        <p>{{ blog.created_at }}</p>
        <router-link :to="'/blog/'+blog.slug">View</router-link>
        <hr>

    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestBlogs',
    computed: {
        blogs() {
            return store.state.blog.blogs;
        },
    },

    mounted() {
        store.dispatch('blog/getBlogs');
    },

    
};
</script>

<style lang="scss" scoped>

</style>