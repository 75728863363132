<template>
    <h4>Orders</h4>
    <div v-for="(order,i) in orders">
        <div>
            <h3>Order #{{ order.order_slug }}</h3>
            <p> Status: 
                <span v-if="order.order_status == 1">Under Review</span>
                <span v-if="order.order_status == 2">Ordered</span>
                <span v-if="order.order_status == 3">Shipped</span>
                <span v-if="order.order_status == 4">Delivered</span>
            </p>
            <p> Type: 
                <span v-if="order.order_status == 1">Card</span>
            </p>
            <p>Link : {{ order.link }}</p>
            <p>Ordered at : {{ order.created_at }}</p>
            <router-link :to="'/dashboard/orders/'+order.order_slug">View Order</router-link>
        </div>
        
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestOrders',

    mounted() {
        store.dispatch('orders/LoadOrders').then((response) => {
            //remove loader
        });
    },
    computed: {
        orders(){
            return store.state.dashboard.orders.orders;
        }
    },
};
</script>

<style lang="scss" scoped>

</style>