<template>
    <h3>Open New Request</h3>
    <hr>
    <input v-model="title" type="text" placeholder="Title"><br>
    <textarea v-model="description" placeholder="Description" rows="8"></textarea><br>
    <button @click="submit">Submit</button>
    <hr>
    <router-link to="/requests">My Requests</router-link>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestCreateRequest',

    data() {
        return {
            title : '',
            description : ''
        };
    },
    methods : {
        submit(){
            store.dispatch('contact/createRequest', {
                title : this.title,
                description : this.description
            }).then((response) => {
                this.$router.push('/request/' + response+'?status=success');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    input , textarea {
        margin: 4px;
        padding: 4px;
        width : 400px
    }
</style>