import axios from '@/axios.js';
export default {
    namespaced: true,
    state: {
        cards: null
    },
    mutations : {
        SET_CARDS(state, cards){
            state.cards = cards
        }
    },
    actions : {
        LoadCards({state,commit}){
            if(state.cards) return;
            return axios.get('/cards/get').then((response) => {
                if(response.data.status === 'success'){
                    commit('SET_CARDS', response.data.cards)
                }
            });
        },
        getCard({state,commit}, slug){
            if(state.cards){
                return state.cards.find((cards) => cards.slug === slug)
            }
            return axios.get('/cards/get/' + slug).then((response) => {
                if(response.data.status === 'success'){
                    return response.data.card
                }
            });
            
        },
        checkSecret({state,commit}, secret_key){
            return axios.get('/cards/secret/' + secret_key).then((response) => {
                return response.data
            });
        }
    }
}