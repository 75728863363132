<template>
    <div>
      <div v-if="request">
        <h2>Request #{{ request.request_slug }}</h2>
        <h4 v-if="response">{{ response }}</h4>

        <p>
          Status:
          <span v-if="request.status == 1">Opened</span>
            <span v-if="request.status == 2">Pending</span>
            <span v-if="request.status == 3">Closed</span>
            <span v-if="request.status == 4">Solved</span>
        </p>
        <h4>{{ request.title }}</h4>
        <p>{{ request.description }}</p>
        <p>Created at : {{ request.created_at }}</p>
        <h3>Replies</h3>
        <div style="border: 2px solid black" v-for="(reply,i) in replies">
            <h4>{{reply.admin ? 'Admin' : 'User'}} : </h4>
            <p>{{ reply.content }}</p>
            <p>Created at : {{ reply.created_at }}</p>
        </div>
        <hr>
      </div>
      <textarea  placeholder="Add a Reply" v-model="reply" rows="6"></textarea><br>
        <button @click="addReply">Add Reply</button>
        <hr>
        <button @click="changeState(3)">Mark as closed</button>
        <button @click="changeState(4)">Mark as solved</button>

    </div>
  </template>
<script>
import store from "@/store";
export default {
    name: 'NfcsApiTestRequest',

    data() {
        return {
            request : null,
            replies : null,
            reply : '',
            response : ''
        };
    },
    methods : {
        addReply(){
            store.dispatch('admin/addReply',{
                request_id : this.request.id,
                content : this.reply
            }).then((response) => {
                if(response.status == "success"){
                    this.reply = '';
                    this.response = "Reply Added";
                }
            })
        },
        changeState(state){
            store.dispatch('admin/changeState',{
                request_id : this.request.id,
                state : state
            }).then((response) => {
                if(response.status == "success"){
                    this.request.status = state;
                }
            })
        }
    },
    mounted() {
        store.dispatch('admin/getRequest',this.$route.params.id).then((response) => {
            //remove loader
            this.request = response.request;
            this.replies = response.replies;
        })
    },
};
</script>

<style lang="scss" scoped>

</style>