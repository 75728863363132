import Blogs from '../views/blog/Blogs.vue'
import Blog from '../views/blog/Blog.vue'
export default [
    {
        path: '/blog',
        name: 'blogs',
        component : Blogs
    },
    {
        path: '/blog/:slug',
        name: 'blog',
        component : Blog
    }
]