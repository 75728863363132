<template>
     <div v-for="(card,i) in cards">
        <h4>Card #{{ card.card_slug }}</h4>
        <p> Status: 
            <span v-if="card.status == 0">Not Activated</span>
            <span v-if="card.status == 1">Activated</span>
        </p>
        <p>Link: {{ card.link }}</p>
        <router-link :to="'/dashboard/cards/'+card.card_slug">Details</router-link>

     </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestCards',


    mounted() {
        store.dispatch('cards/LoadCards').then((response) => {
            //remove loader
        });
    },
    computed: {
        cards(){
            return store.state.dashboard.cards.cards;
        }
    },
};
</script>

<style lang="scss" scoped>

</style>