<template>
    <h2>Login</h2>
    <input type="text" placeholder="username" v-model="username"><br>
    <input type="password" placeholder="password" v-model="password"><br>
    <button @click="login">Login</button><br>
    <div><h3 v-if="response">{{response}}</h3></div>
    <hr>
    <router-link to="/auth/register">Register</router-link><br>
    <router-link to="/auth/forgot-password">Forget password</router-link><br>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestRegister',

    data() {
        return {
            username : "",
            password : "",
            response : ""
        };
    },
    methods: {
        login(){
            store.dispatch('auth/login', {
                username: this.username, 
                password: this.password
            }).then((response) => {
                if(response.status == "success"){
                    this.response = "Login success";
                }else{
                    this.response = response.message;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
input , button {
    margin: 4px;
}
</style>