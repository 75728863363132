import axios from '@/axios.js';
export default {
    namespaced: true,
    state: {
        orders: null
    },
    mutations : {
        SET_ORDERS(state, orders){
            state.orders = orders
        }
    },
    actions : {
        makeOrder({commit}, payload){
            return axios.post('/orders/create', payload).then((response) => {
                // Add order to state
                return response.data
            })
        },
        LoadOrders({state,commit}){
            if(state.orders) return;
            return axios.get('/orders/get').then((response) => {
                if(response.data.status === 'success'){
                    commit('SET_ORDERS', response.data.orders)
                }
            });
        },
        getOrder({state,commit}, slug){
            if(state.orders){
                return state.orders.find((order) => order.slug === slug)
            }
            return axios.get('/orders/get/' + slug).then((response) => {
                if(response.data.status === 'success'){
                    return response.data.order
                }
            });
            
        }
    }
}