import axios from '@/axios.js'
export default {
    namespaced : true,
    state : {
        blogs : null
    },
    mutatuions : {
       
    },
    actions : {
        getBlogs({state}){
            return axios.get('/blogs/get').then((response) => {
                if(response.data.status === 'success'){
                    state.blogs = response.data.blogs
                }
            });
        },
        getBlog({state}, slug){
            if(state.blogs){
                return state.blogs.find((blog) => blog.slug === slug)
            }
            return axios.get('/blogs/get/' + slug).then((response) => {
                if(response.data.status === 'success'){
                    return response.data.blog
                }
            });
        }
    },
}