<template>
  <div>
    Activate Card <span v-if="card">#{{ card.card_slug }}</span>
  </div>
  <div v-if="card" style="margin-top: 20px">
    <!-- (A) NFC READER -->
    <h2>Approch The Card</h2>
    <div id="demoMSG">
      <!-- The container to display log messages -->
      {{ isSupported ? "NFC Supported" : "NFC Not Supported"  }}
    </div>
    <button @click="write" >Write NFC Tag</button>
    <button @click="read" :disabled="!isSupported">Read NFC Tag</button>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "NfcsApiTestActivateCard",

  data() {
    return {
      card: null,
      nfc: null,
      isSupported:"NDEFReader" in window,
    };
  },

  mounted() {
    store
      .dispatch("cards/checkSecret", this.$route.params.secret_key)
      .then((res) => {
        if (res.status == "success") {
          this.card = res.card;
        }
      });
  },

  methods: {
    write() {
        const ndef = new NDEFReader();
        ndef.write(nfc.hTxt.value)
        .then(() => alert("Message written!"))
        .catch(err => nfc.logger("ERROR - " + err.message));
        },
  },
};
</script>

<style lang="scss" scoped></style>
