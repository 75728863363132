<template>
    <h2>Register</h2>
    <input type="text" placeholder="name" v-model="name"><br>
    <input type="email" placeholder="email" v-model="email"><br>

    <input type="text" placeholder="username" v-model="username"><br>
    <input type="password" placeholder="password" v-model="password"><br>
    <input type="password" placeholder="confirm-password" v-model="confirm_password"><br>
    <button @click="register()">Register</button><br>
    <div><h3 v-if="response">{{response}}</h3></div>
    <hr>
    <router-link to="/auth/login">Login</router-link><br>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestRegister',

    data() {
        return {
            username : "",
            name : "",
            email : "",
            password : "",
            confirm_password : "",
            response : ""
        };
    },
    methods: {
        register(){
            store.dispatch('auth/register', {
                username: this.username, 
                name: this.name, 
                email: this.email, 
                password: this.password
            }).then((response) => {
                if(response.status == "success"){
                    this.response = "Register success, please login";
                }else{
                    this.response = response.message;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
input , button {
    margin: 4px;
}
</style>