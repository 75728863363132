<template>
    <h2>Orders</h2>
    <div v-for="(order,i) in orders">
        <h3>Order #{{ order.order_slug }}</h3>
        <p>Order Type :
            <span v-if="order.order_type == 1">Card</span>
        </p>
        <p>Order Status: 
            <span v-if="order.order_status == 1">Under Review</span>
            <span v-if="order.order_status == 2">Ordered</span>
            <span v-if="order.order_status == 3">Shipped</span>
            <span v-if="order.order_status == 4">Delivered</span>
        </p>
        <p>Order Created at : {{ order.created_at }}</p>
        <router-link :to="'/admin/orders/' + order.id">Open</router-link>
        <hr>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestOrders',
    computed: {
        orders(){
            return store.state.admin.orders
        }
    },
    mounted() {
        store.dispatch('admin/getOrders')
    },
};
</script>

<style lang="scss" scoped>

</style>