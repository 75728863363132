<template>
    <router-link :to='"/admin/orders"'>All Orders</router-link>
        <hr>
    <h2 v-if="response">{{ response }}</h2>
    <div v-if="order">
        <h3>Order #{{ order.order_slug }}</h3>
        <p>Order Type :
            <span v-if="order.order_type == 1">Card</span>
        </p>
        <p>Order Status: 
            <span v-if="order.order_status == 1">Under Review</span>
            <span v-if="order.order_status == 2">Ordered</span>
            <span v-if="order.order_status == 3">Shipped</span>
            <span v-if="order.order_status == 4">Delivered</span>
        </p>
        <p>Order Created at : {{ order.created_at }}</p>
        <h3>Order Details</h3>
        <p>City: {{ order.order_details.city }}</p>
        <p>Address: {{ order.order_details.address }}</p>
        <p>Phone Number: {{ order.order_details.phone }}</p>
        <p>Payment Method: 
            <span v-if="order.order_details.payment_method == 1">Cash</span>
        </p>
        <button @click="changeOrderState(1)" v-if="order.order_status == 1">Accept Order</button>
        <button @click="changeOrderState(0)" v-if="order.order_status == 1">Reject Order</button>
        <hr>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestOrder',

    data() {
        return {
           order : null 
        };
    },

    mounted() {
        store.dispatch('admin/getOrder',this.$route.params.id).then((response) => {
            //remove loader
            this.order = response.order;
        })        
    },
    methods : {
        changeOrderState(state){
            store.dispatch('admin/changeOrderState',{
                order_id : this.order.id,
                state : state
            }).then((response) => {
                if(response.status == "success"){
                    if(state == 1){
                        this.order.order_status = 2;
                    }else{
                        this.order = null;
                        this.response = "Order Rejected";
                    }
                }
            })
        }
    }

};
</script>

<style lang="scss" scoped>

</style>