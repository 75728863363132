<template>
    <h2>Dashboard</h2>
    <hr>
    <h4>Orders</h4>
    <a href="/dashboard/orders">My Orders</a><br>
    <a href="/dashboard/make-order">Create Order</a>
    <hr>
    <h4>Cards</h4>
    <a href="/dashboard/cards">My Cards</a><br>
    <a href="/dashboard/make-order?category=card">Create Card</a>
    <hr>
    <h4><a href="/contact">Contact us</a></h4>
    <hr>
    <h4><a href="/blog">Blog</a></h4>
</template>

<script>

export default {
    name: 'NfcsApiTestDashboard',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
       
    },
};
</script>

<style lang="scss" scoped>
    a {
        margin: 4px;
    }
</style>