<template>
    <h2>Reset Password</h2>
    <input type="password" placeholder="password" v-model="password"><br>
    <input type="password" placeholder="confirm-password" v-model="confirm_password"><br>
    <button @click="reset_password">Reset Password</button><br>
    <div><h3 v-if="response">{{response}}</h3></div>
    <hr>
    <router-link to="/auth/login">Login</router-link><br>
</template>

<script>
import store from '@/store';

export default {
    name: 'NfcsApiTestRegister',
    methods: {
        reset_password(){
            store.dispatch('auth/resetPassword', {
                password: this.password,
                confirm_password: this.confirm_password,
                token: this.$route.params.token
            }).then((response) => {
                if(response.status == "success"){
                    this.response = "Password Reset Success, please login";
                }else{
                    this.response = response.message;
                }
            });
        }
    },
    data() {
        return {
            password : "",
            confirm_password : "",
            response : ""
        };
    },
    
};
</script>

<style lang="scss" scoped>
input , button {
    margin: 4px;
}
</style>