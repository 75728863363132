import { createStore } from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import contact from './modules/contact'
import blog from './modules/blog'
import admin from './modules/admin'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth , dashboard , contact , blog ,  admin
  }
})
