<template>
    <h2>Users</h2>
    <h5 v-if="response">{{ response }}</h5>
    <div v-for="(user,i) in users">
        <h3>User #{{ user.id }}</h3>
        <p>Username: {{ user.username }}</p>
        <p>Email: {{ user.email }}</p>
        <p>Joined in : {{ user.created_at }}</p>
        <button @click="deleteUser(user.id)">Delete</button>
        <hr>
        
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestUsers',
    data() {
        return {
            response: '',
        };
    },
    mounted() {
        store.dispatch('admin/getUsers')
    },
    computed: {
        users() {
            return store.state.admin.users;
        }
    },
    methods: {
        deleteUser(id){
            store.dispatch('admin/deleteUser',id).then((res) => {
                if(res.status == "success"){
                    this.response = "User Deleted";
                }else{
                    this.response = res.message;
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>

</style>