<template>
    <h2>Requests</h2>
    <div v-for="(request,i) in requests">
        <h3>Request #{{ request.request_slug }}</h3>
        <p> Status: 
            <span v-if="request.status == 1">Opened</span>
            <span v-if="request.status == 2">Pending</span>
            <span v-if="request.status == 3">Closed</span>
            <span v-if="request.status == 4">Solved</span>
        </p>
        <h2>{{ request.title }}</h2>
        <p>{{ request.description }}</p>
        <p>Created at : {{ request.created_at }}</p>
        <router-link :to="'/admin/requests/' + request.id">Open</router-link>
        <hr>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestRequests',

    data() {
        return {
            
        };
    },

    mounted() {
       store.dispatch('admin/getRequests') 
    },

    computed: {
        requests() {
            return store.state.admin.requests;
        }
    },
};
</script>

<style lang="scss" scoped>

</style>