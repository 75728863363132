<template>
  <div>
    <h2>Order #{{ this.$route.params.slug }}</h2>
    <div v-if="order">
      <p>
        Status:
        <span v-if="order.order_status == 1">Under Review</span>
        <span v-if="order.order_status == 2">Ordered</span>
        <span v-if="order.order_status == 3">Shipped</span>
        <span v-if="order.order_status == 4">Delivered</span>
      </p>
      <p>
        Type:
        <span v-if="order.order_status == 1">Card</span>
      </p>
      <p>Link : {{ order.link }}</p>
      <p>Ordered at : {{ order.created_at }}</p>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "NfcsApiTestOrder",
  data() {
    return {
      order: null
    };
  },
  mounted() {
    store.dispatch("orders/getOrder",this.$route.params.slug).then((response) => {
      //remove loader
        this.order = response;
    });
  }
};
</script>

<style lang="scss" scoped></style>
