<template>
    <h2>Confirm Email</h2>
    <h4>Enter Your Email</h4>
    <input type="email" placeholder="email" v-model="email"><br>
    <button @click="send_email">Send Confirm Email</button><br>
    <div><h3 v-if="response">{{response}}</h3></div>
</template>

<script>
import store from '@/store';
export default {
    name: 'NfcsApiTestRegister',

    data() {
        return {
            email : "",
            response : ""   
        };
    },
    methods: {
        send_email(){
            store.dispatch('auth/sendConfirmEmail', {
                email: this.email
            }).then((response) => {
                if(response.status == "success"){
                    this.response = "An Confirm Email Link has been sent to your email address";
                }else{
                    this.response = response.message;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
    input , button {
        margin: 4px;
    }
</style>