import CreateRequest from '../views/contact/CreateRequest.vue'
import Requests from '../views/contact/Requests.vue'
import Request from '../views/contact/Request.vue'
export default [
    {
        path: '/create-request',
        name: 'create-request',
        component : CreateRequest
    },
    {
        path: '/requests',
        name: 'requests',
        component : Requests
    },
    {
        path: '/requests/:slug',
        name: 'request',
        component : Request
    }

]