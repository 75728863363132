import axios from "@/axios.js";
export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("admin-token") || "",
    access: localStorage.getItem("admin-access") || "",
    admins: null,
    requests: null,
    users: null,
    orders: null,
  },
  mutations: {
    deleteAdmin(state, id) {
      state.admins = state.admins.filter((admin) => admin.id != id);
    },
    deleteUser(state, id) {
      state.users = state.users.filter((user) => user.id != id);
    },
  },
  actions: {
    login({ commit }, user) {
      return axios
        .post("admin/login", {
          username: user.username,
          password: user.password,
        })
        .then((response) => {
          if (response.data.status == "failed") return response.data;
          localStorage.setItem("admin-token", response.data.token);
          localStorage.setItem("admin-access", response.data.access);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;
          return response.data;
        });
    },
    //Admins
    addAdmin({ commit }, admin) {
      return axios
        .post("admin/admins", {
          username: admin.username,
          password: admin.password,
          access: admin.access,
        })
        .then((response) => {
          return response.data;
        });
    },
    getAdmins({ state }) {
      if (state.admins != null) return state.admins;
      axios.get("admin/admins").then((response) => {
        if (response.data.status == "failed") return false;
        state.admins = response.data.admins;
      });
    },
    deleteAdmin({ commit }, id) {
      return axios.delete("admin/admins/" + id).then((response) => {
        if (response.data.status == "success") {
          commit("deleteAdmin", id);
        }
        return response.data;
      });
    },
    //Requests
    getRequests({ state }) {
      if (state.requests != null) return state.requests;
      return axios.get("admin/requests").then((response) => {
        state.requests = response.data.requests;
      });
    },
    getRequest({ state }, id) {
      if (state.requests != null) {
        return state.requests.find((request) => request.id == id);
      }
      return axios.get("admin/requests/" + id).then((response) => {
        return response.data;
      });
    },
    addReply({ state }, reply) {
      return axios
        .post("admin/requests", {
          id: reply.request_id,
          content: reply.content,
        })
        .then((response) => {
          return response.data;
        });
    },
    changeState({ state }, request) {
      return axios
        .post("admin/requests/state", {
          id: request.request_id,
          state: request.state,
        })
        .then((response) => {
          return response.data;
        });
    },
    //Users
    getUsers({ state }) {
      if (state.users != null) return state.users;
      return axios.get("admin/users").then((response) => {
        state.users = response.data.users;
      });
    },
    deleteUser({ commit }, id) {
      return axios.delete("admin/users/" + id).then((response) => {
        if (response.data.status == "success") {
          commit("deleteUser", id);
        }
        return response.data;
      });
    },
    // Orders
    getOrders({ state }) {
      if (state.orders != null) return state.orders;
      return axios.get("admin/orders").then((response) => {
        state.orders = response.data.orders;
      });
    },
    getOrder({ state }, id) {
      return axios.get("admin/orders/" + id).then((response) => {
        return response.data;
      });
    },
    changeOrderState({ state }, order) {
      return axios
        .post("admin/orders/state", {
          id: order.order_id,
          state: order.state,
        })
        .then((response) => {
          if (response.data.status == "success") {
            //
            if (state.orders != null) {
              if (order.state == 1) {
                //update order order_status
                state.orders.find((o) => o.id == order.order_id).order_status = 2;
              } else {
                //remove order from orders
                state.orders = state.orders.filter(
                  (order) => order.id != order.order_id
                );
              }
            }
          }
          return response.data;
        });
    },
  },
};
